// Site variables
export let site = {
    name: 'SexToysDistribution',
    motto: {
        ro: 'Dorinta * Fantezie * Pasiune',
        en: 'Lust * Fantasy * Passion'
    },
    pages: {
        home: {
            title: {
                ro: 'Sex shop SexToysDistribution.com - Dorinta * Fantezie * Pasiune',
                en: 'SexToysDistribution.com - Lust * Fantasy * Passion'
            },
            description: {
                ro: 'Explorați plăcerea și îndrăzneala cu SexToysDistribution.com, magazinul online al pasiunii și erotismului. Găsiți o gamă variată de produse erotice de înaltă calitate, de la jucării intime la lenjerie seducătoare. Aduceți-vă viața sexuală la un nivel superior cu experiența discretă și livrare rapidă de la SexToysDistribution.com',
                en: 'Explore pleasure and daring with SexToysDistribution.com, the online store of passion and eroticism. Discover a diverse range of high-quality erotic products, from intimate toys to seductive lingerie. Elevate your sexual life to the next level with discreet experiences and fast delivery from SexToysDistribution.com.'
            }
        }
    },
    about: {
        ro: 'Dorim să oferim produse de calitate premium la prețuri accesibile, cu livrare rapidă și suport continuu pentru clienți.',
        en: 'We want to offer Premium Quality products at affordable prices with fast delivery and continuous customer support.'
    },
    showNewsletterPopup: false,
    showNewsletterDiscountText: false,
}

// Site languages
export let languages = [
    {
        iso_693_1_code: 'en',
        iso_name: 'English',
        flag: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30"><clipPath id="t"><path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z"/></clipPath><path d="M0,0v30h50v-30z" fill="#012169"/><path d="M0,0 50,30M50,0 0,30" stroke="#fff" stroke-width="6"/>    <path d="M0,0 50,30M50,0 0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/><path d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z" fill="#C8102E" stroke="#FFF" stroke-width="2"/></svg>'
    },
    {
        iso_693_1_code: 'ro',
        iso_name: 'Romana',
        flag: '<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ro" viewBox="0 0 512 512"><g fill-rule="evenodd" stroke-width="1pt"><path fill="#00319c" d="M0 0h170.7v512H0z"/><path fill="#ffde00" d="M170.7 0h170.6v512H170.7z"/><path fill="#de2110" d="M341.3 0H512v512H341.3z"/></g></svg>'
    }
];

// 2Performant
export let tooperformant = {
    enabled: false,
    campaign_unique: '',
    confirm: ''
}

// Store configuration
export let store = {
    product_max_quantity: 500,
    limitMaxQuantity(value) {
        return (value > this.product_max_quantity) ? this.product_max_quantity : value
    },
    gift_wrap_uuid: '2933ec47-b4eb-315c-8b2f-85d4348f',
    free_shipping_threshold: 100000,
    product_tile_button_template: 'button',
    add_to_cart_confirmation_popup : false,
    add_to_cart_quantity_controls : true,
    clear_cart_button : true,
    checkout_type: 'b2b_checkout',
    checkout_hide_shipping: true,
    checkout_show_vat : true,
    product_page : {
        product_details_tabs : [
          'specs','description'
        ],
        show_discreet_shipping: false,
        show_shipping_cost : false,
        show_phone_numbers : false,
    }
}

export let footer_navigation_1_data = {
    'ro': [
        {
            title: 'Detalii livrare',
            route: {
                name: null,
                slug: 'detalii-livrare'
            },
            children: []
        },
    ],
    'en': [
        {
            title: 'Delivery details',
            route: {
                name: null,
                slug: 'delivery-details'
            },
            children: []
        },
    ]
}


export let footer_navigation_2_data = {
    'ro': [
        {
            title: 'Contactati-ne',
            route: {
                name: null,
                slug: 'contact'
            },
            children: []
        },
    ],
    'en': [
        {
            title: 'Contact us',
            route: {
                name: null,
                slug: 'contact-us'
            },
            children: []
        },
    ]
}


export let contact_information = {
    email: 'sales@sextoysdistribution',
    phones: [
        {
            'label': '(+40) 720.004.515',
            'value': '+40720004515'
        },
        {
            'label': '(+40) 751.557.555',
            'value': '+40751557555'
        }
    ],
    whatsapp_phone_number: '+40751557555',
    show_whatsapp_button: true,
    program: {
        ro: 'Zilnic 8:30 - 20:30',
        en: 'Daily 8:30 - 20:30'
    }
}